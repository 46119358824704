<template>
    <div>
        <div>
            <div class="navbar" :class="{ 'show': showNavbar }">
                <input type="text" class="searchbox-header" :placeholder="$t('searchByName')" v-model="searchVal"
                    @input="handleSearch()" />
                <div class="category-container mt-1">
                    <CButton :class="getCategoryClass(null)" @click="handleCategoryModal(null)">
                        {{ $t('allProduct') }}
                    </CButton>
                    <CButton v-for="(category, index) in categories" :key="category.objectId" :value="category.objectId"
                        :class="getCategoryClass(category)" @click="handleCategoryModal(category)">
                        {{ category.name }}
                    </CButton>
                </div>
            </div>
            <div class="mt-3 p-2">
                <div>
                    <CRow>
                        <CCol>
                            <h5>{{ $t('foodCategory') }}</h5>
                        </CCol>
                        <CCol>
                            <p data-toggle="modal" data-target="#categoryModal" class="text-grey"
                                style="text-align: end;">
                                {{ $t('viewAll') }}
                            </p>
                        </CCol>
                    </CRow>
                </div>
                <div class="category-container">
                    <CButton :class="getCategoryClass(null)" @click="handleCategoryModal(null)">
                        {{ $t('allProduct') }}
                    </CButton>
                    <CButton v-for="(category, index) in categories" :key="category.objectId" :value="category.objectId"
                        :class="getCategoryClass(category)" @click="handleCategoryModal(category)">
                        {{ category.name }}
                    </CButton>
                </div>
            </div>
            <div>
                <input type="text" class="searchbox" :placeholder="$t('searchByName')" v-model="searchVal"
                    @input="handleSearch()" />
            </div>
        </div>
        <MenuLayout class="mt-1 mb-5" v-bind:cartTotal="cartTotal" v-bind:display="display"
            v-bind:categoryObjectId="categoryObjectId" v-bind:searchVal="searchVal" @update-cartTotal="updateCartTotal"
            :key="componentKey"></MenuLayout>
        <br />
        <footer class="fixed-bottom mb-6">
            <img @click="setScrollPosition()" src="/img/up-arrow.png" v-if="showNavbar" :style="upArrowStyle">
            <div class="p-2 pb-3 pt-3 bg-white shadow-sm" v-if="cartTotal > 0">
                <div class="text-black" style="text-decoration: none;">
                    <CButton :disabled="loading" @click="goToCart()" class="btn-sm btn-selecteditem" block>
                        <CRow class="pt-2 pb-2">
                            <CCol>
                                <div class="text-left ml-3 text-white" style="white-space: nowrap;">
                                    {{ $t('basket') }} • {{ cartTotal }} {{ $t('item') }}
                                </div>
                            </CCol>

                            <CCol>
                                <div class="text-right mr-3">
                                    THB {{ totalCost }}
                                    <CSpinner v-if="loading" class="mb-2" size="sm" />
                                </div>
                            </CCol>
                        </CRow>
                    </CButton>
                </div>
            </div>
        </footer>


        <!-- Category Modal -->
        <div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
            data-backdrop="false">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="mt-3 ml-3 d-flex justify-content-end">
                        <small class="" data-dismiss="modal" aria-label="Close">
                            <strong class="pr-4">
                                <img src="/img/close.png" width="40px" style="opacity: 0.8;">
                            </strong>
                        </small>
                    </div>
                    <div class="modal-body">
                        <h4 class="text-center mb-4">{{ $t('foodCategory') }}</h4>
                        <div class="row text-center category-row" style="overflow-y: auto;">
                            <div class="col-6 text-center mb-4">
                                <div>
                                    <CButton style="font-size: large;" class="btn-square-category"
                                        @click="handleCategoryModal(null)" data-dismiss="modal">
                                        <i class="fi fi-rr-border-all" style="font-size: 40px;"></i>
                                        <br>{{ $t('allProduct') }}
                                    </CButton>
                                </div>
                            </div>
                            <div v-for="(category, index) in categories" :key="category.objectId"
                                :value="category.objectId" class="col-6 text-center mb-4">
                                <div v-if="category.indexIcon == undefined || category.indexIcon == ''">
                                    <CButton style="font-size:x-large;" class="btn-square-category"
                                        @click="handleCategoryModal(category)" data-dismiss="modal">
                                        {{ category.name }}
                                    </CButton>
                                </div>
                                <div v-else>
                                    <CButton style="font-size: large;" class="btn-square-category"
                                        @click="handleCategoryModal(category)" data-dismiss="modal">
                                        <i :class="category.indexIcon" style="font-size: 40px;"></i>
                                        <br>{{ category.name }}
                                    </CButton>
                                </div>
                            </div>
                            <div class="col-6 text-center mb-4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuLayout from './AppMenuLayout';
import poscrm from '@/services/poscrm';
import util from '@/util/util';
import '@/util/menu.css';
import { CButton, CCol, CRow } from '@coreui/vue';
import Detail from './AppProductDetails';
import Banner from './AppBanners';
import Header from './AppHeader';
import RecommendProducts from './AppRecommendProducts';
import pos from '@/services/pos'

export default {
    components: {
        MenuLayout,
        RecommendProducts,
        Header,
        Banner,
        CButton,
        CCol,
        CRow,
        Detail,
    },
    data() {
        return {
            cartTotal: 0,
            products: [],
            categories: [],
            totally: 0,
            cartLists: [],
            display: 'list',
            totalCost: 0,
            componentKey: 0,
            categoryObjectId: '',
            table: {},
            orderCompleted: false,
            magicFlag: false,
            searchVal: '',
            categoryName: this.$i18n.t('allProduct'),
            callStaffModal: false,
            showNavbar: false,
            lastScrollPosition: 0,
            reccomendProduct: [],
            SKUObjectId: '',
            items: [],
            modalToggle: '',
            greetingMessage1: '',
            greetingMessage2: '',
            orderShopSettingCollection: [],
            selectedCategory: null,
            loading: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        OrderType() {
            let type = window.location.pathname;
            if (type === '/delivery/menulists') {
                return 'delivery';
            } else {
                return 'qr';
            }
        },
        uid() {
            return `${localStorage.getItem('uid')}`;
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`;
        },
        shop() {
            return this.shopLineOA.find((detail) => detail.objectId === this.shopObjectId);
        },
        shopName() {
            if (this.shop) {
                return this.shop.shopName;
            } else {
                return '';
            }
        },
        logo() {
            if (this.shop) {
                return this.shop.remoteImagePath;
            } else {
                return '';
            }
        },
        noImg() {
            return process.env.VUE_APP_NOIMAGE;
        },
        upArrowStyle() {
            return {
                width: '50px',
                height: '50px',
                position: 'fixed',
                right: '10px',
                bottom: this.cartTotal > 0 ? '100px' : '10px',
                opacity: 0.8
            };
        },
    },
    created() {
        this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || [];
        this.getCategory();
        this.getProduct();
        this.getReceiptTransaction();
        this.getOrderShopSetting()
        this.selectedCategory = null
        this.getCartList()

    },
    methods: {
        ...util,
        async getCartList() {
            if (this.OrderType == 'qr') {
                this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
                this.getCartTotal()
            }
            else if (this.OrderType == 'delivery') {
                try {
                    if (localStorage.getItem('token') && localStorage.getItem('profile')) {
                        const uid = localStorage.getItem('uid')
                        const profile = JSON.parse(localStorage.getItem('profile'))
                        const res = await pos({
                            url: `/api/v1.3/${uid}/profile/cart?profileObjectId=${profile.objectId}&shopObjectId=${this.shopObjectId}&limit=100`,
                            method: 'get',

                        })
                        if (res.data.error.code == 0) {
                            this.cartLists = res.data.data
                            let total = this.cartLists.reduce((accum, product) => {
                                return accum + product.quantity;
                            }, 0)
                            this.cartTotal = total
                        } else if (res.data.error.code == 402) {
                            this.renewToken()
                        } else if (res.data.error.code == 4000) {
                            this.logOut()
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        },
        async renewToken() {
            try {
                if (localStorage.getItem('refreshToken')) {
                    const refreshToken = localStorage.getItem('refreshToken')

                    let res = {}
                    res = await pos({
                        url: `/auth/v1.0/renewtoken/otp`,
                        method: 'post',
                        data: {
                            "refreshToken": refreshToken
                        }
                    });
                    if (res.data?.error?.code == 0) {
                        localStorage.setItem('token', res.data?.data?.token)
                        window.location.reload()
                    } else {
                        this.logOut()
                    }
                }
            } catch (error) {
                console.error(error);
            }
        },
        logOut() {
            const uid = localStorage.getItem('uid')
            const shopObjectId = localStorage.getItem('shopObjectId')
            sessionStorage.clear()
            localStorage.clear()
            localStorage.setItem('uid', uid)
            localStorage.setItem('shopObjectId', shopObjectId)
            this.$router.push('/delivery/home')
        },
        getCategoryClass(category) {
            if (category !== null) {
                let objectId = category?.objectId
                return objectId === this.selectedCategory ? 'category-btn category-btn-active' : 'category-btn';
            } else {
                return this.selectedCategory == null ? 'category-btn category-btn-active' : 'category-btn';
            }
        },
        async getOrderShopSetting() {
            const uid = `${localStorage.getItem('uid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            let params = {
                shopObjectId: shopObjectId,
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v2.1/' + uid + '/OrderShopSetting',
                    params: params,
                }).then((res) => {
                    this.display = res.data?.data[0]?.layout || 'list'
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        goToCart() {
            this.loading = true
            if (this.OrderType == 'delivery') {
                this.$router.push('/delivery/mycart');
            } else {
                this.$router.push('/dine-in/selecteditem');
            }
        },
        setErrorImg(event, image) {
            if (event.target.src == image) {
                event.target.src = this.noImg;
            } else {
                event.target.src = image;
            }
        },
        isAllowToShowDetailModal(product) {
            if (product.ProductSKU.skuType !== 'P') {
                return 'modal';
            }
            if (product.ProductSKU.skuType == 'P' && this.isAllowToOrder(product) == true) {
                return 'modal';
            }
        },
        isAllowToOrder(product) {
            if (product.ProductSKUStock.onhandQty > product.ProductSKUStock.notAllowMinQty) {
                return true;
            } else if (product.ProductSKUStock.notAllowMinQty == null) {
                return true;
            } else {
                return false;
            }
        },
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        setScrollPosition() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },
        productDetailHandler(product) {
            this.trackButtonClick('menulist/ProductDetail(RecommendMenu)');
            this.SKUObjectId = product.ProductSKU.objectId;
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition >= 700) {
                this.showNavbar = true;
            } else {
                this.showNavbar = false;
            }
        },
        getCategoryName() {
            return this.categoryName;
        },
        handleDisplay(opt) {
            this.display = opt;
        },
        handleCategory(e) {
            this.trackButtonClick('menulist/ChangeCategory');
            this.categoryObjectId = e.target.value;
        },
        handleCategoryModal(value) {
            this.selectedCategory = value?.objectId || null
            if (value != null) {
                this.categoryObjectId = value.objectId;
                this.categoryName = value.name;
            } else {
                this.categoryObjectId = null;
                this.categoryName = this.$i18n.t('allProduct');
            }
        },
        async getReceiptTransaction() {
            let receiptObjectId = localStorage.getItem('receiptObjectId') || null;
            const uid = `${localStorage.getItem('uid')}`;
            if (receiptObjectId != null) {
                try {
                    await poscrm.get('/api/v1.1/' + uid + '/Receipt/data/' + receiptObjectId).then((res) => {
                        if (res.data?.data?.document != null) {
                            let document = {}
                            document = res.data?.data?.document
                            if (document.receiptStatus === 'A') {
                                this.orderCompleted = true;
                                localStorage.clear();
                                sessionStorage.clear();
                            }
                        } else {
                            localStorage.clear();
                            sessionStorage.clear();
                            this.$router.push('/texp');
                        }
                    });
                } catch (error) {
                    throw new Error(error);
                }
            } else {
                let uid = localStorage.getItem('uid');
                if (uid == null) {
                    this.orderCompleted = true;
                }
            }
        },
        async getProduct(page = 1) {
            const uid = this.uid;
            const categoryObjectId = this.categoryObjectId;
            let params = {
                shopObjectId: this.shopObjectId,
                page: page,
                limit: 50,
                searchVal: this.searchVal,
            };
            if (categoryObjectId !== '') {
                params['categoryObjectId'] = categoryObjectId;
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v1.1/' + uid + '/Shop/getproduct/view',
                    params: params,
                }).then((res) => {
                    this.reccomendProduct = res.data?.data || {}
                    this.products = res.data?.data || {}
                });
            } catch (error) {
                throw new Error(error);
            }
        },
        recommendedProducts() {
            return this.reccomendProduct.filter((product) => product.ProductSKU.isRecommend === true);
        },
        handleSearch() {
            if (this.searchVal !== '') {
                this.categoryObjectId = '';
            }
        },
        updateCartTotal(total) {
            if (this.OrderType == 'qr') {
                this.cartLists = sessionStorage.getItem('cart') || []
                this.cartTotal = total
                this.updateTotalCost()
                this.$emit('update-cartTotal', total);
                this.$forceUpdate();
            }
            else if (this.OrderType == 'delivery') {
                this.cartLists = total
                let totalCart = this.cartLists.reduce((accum, product) => {
                    return accum + product.quantity;
                }, 0)
                this.cartTotal = totalCart
                this.updateTotalCost()
            }

        },
        updateTotalCost() {
            if (this.OrderType == 'qr') {
                this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
            }
            let total = this.cartLists.reduce((accum, product) => {
                return accum + product.totalAmount
            }, 0)
            this.totalCost = util.convertCurrency(total);
            this.componentKey += 1
        },
        getCartTotal() {
            if (this.OrderType == 'qr') {
                this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
            }
            let total = this.cartLists.reduce((accum, product) => {
                return accum + product.quantity
            }, 0)
            this.cartTotal = total;
        },
        catIcon(catName) {
            return catName.charAt(0).toUpperCase();
        },
        async getCategory() {
            const uid = this.uid;
            let shopObjectId = this.shopObjectId;

            let params = {
                shopObjectId: shopObjectId,
            };

            try {
                await poscrm.get('/api/v1.1/' + uid + '/Shop/getcategory/menu', { params }).then((res) => {
                    this.categories = res.data.data;
                    this.categories.sort((a, b) => a.sequence - b.sequence);
                });
            } catch (error) {
                throw new Error(error);
            }
        },
    },
    watch: {
        cartTotal(newVal, oldVal) {
            this.cartTotal = newVal;
            this.updateTotalCost();

        },
        '$i18n.locale': {
            handler(newVal, oldVal) {
                if (this.categoryName == 'สินค้าทั้งหมด' || this.categoryName == 'All Product') {
                    this.categoryName = this.$i18n.t('allProduct');
                }
            },
            deep: true,
        },
    },
};
</script>
<style>
.custom-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Optional: If you want to horizontally center the text */

}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

.navbar {
    height: fit-content;
    width: 100%;
    background: hsl(0, 0%, 100%);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out;

}

.navbar.show {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Slide in */
}

.recommend-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: inline-flex;
}

.recommend-btn {
    height: 100px;
    width: 100px;
    font-size: medium;
    font-weight: lighter;
    border-radius: 10px;
    background-color: rgba(127, 232, 202, 0.3);
    text-align: center;
    margin: 3px;
    margin-bottom: 10px;
}

.category-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: inline-flex;

}


.category-btn:hover {
    color: white;
}

.category-btn-header {
    height: 35px;
    width: fit-content;
    font-size: small;
    font-weight: lighter;
    border-radius: 10px;
    background-color: #ccc;
    text-align: center;
    margin: 3px;
    white-space: nowrap;
    margin-bottom: 10px;

}

.hearder {
    width: 100%;
}




.category-row {
    margin: 10px;
}

.searchbox-header {
    font-size: 13px;
    border-radius: 10px;
    line-height: 1.5;
    padding: 5px 10px;
    border: 2px solid #eceff0;
    color: rgb(14, 14, 16);
    background: #eceff0;
    display: block;
    height: 45px;
    width: 100%;
    padding-left: 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 10px center;
}

.searchbox {
    font-size: 13px;
    border-radius: 10px;
    line-height: 1.5;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #eceff0;
    color: rgb(14, 14, 16);
    background: #eceff0;
    display: block;
    height: 50px;
    width: 100%;

    :hover {
        border-color: #ccc;
    }

    :focus {
        border-color: #9147ff;
        background: #fff;
    }
}

.banner {
    border-radius: 10px;
    aspect-ratio: 2 / 1;
}

@media screen and (max-width: 376px) {
    .category-row {
        margin: 0px;
    }
}

img.image-inbox {
    position: absolute;
    object-fit: cover;
}

.modal-body {
    padding: 1rem;
}
</style>