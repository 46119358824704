var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.recommendedProducts().length > 0)?_c('div',{staticClass:"mt-3 mb-3"},[_c('h5',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t('recommendMenu'))+" ")]),_c('div',{staticClass:"recommend-container mt-2"},_vm._l((_vm.recommendedProducts()),function(item,index){return _c('div',{key:index,staticClass:"col-4 pr-2 pl-2"},[_c('div',{attrs:{"data-toggle":_vm.isAllowToShowDetailModal(item),"data-target":"#reccomendModal"},on:{"click":function($event){return _vm.productDetailHandler(item)}}},[_c('div',{staticClass:"square-box shadow-menu",style:({
                        'background-image': `url('${_vm.noImg}')`,
                        width: '100%',
                        'background-size': 'cover',
                        'background-position': 'center center',
                    })},[_c('img',{staticClass:"image-inbox",attrs:{"src":_vm.getImageURL(item.remoteImagePath, '200')},on:{"error":function($event){return _vm.setErrorImg($event, item.remoteImagePath)}}}),(!_vm.isAllowToOrder(item))?_c('span',{staticClass:"font-weight-bold add-to-cart-badges-nostock"},[_c('h4',{staticClass:"mt-2 text-center"},[_vm._v(_vm._s(_vm.$t('outOfStock')))])]):_vm._e()])]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mt-2 h5 autoShowHide"},[_vm._v(" "+_vm._s(item.ProductSKU.SKUName)+" ")]),_vm._m(0,true)]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"text-grey pl-1 pb-3"},[_vm._v(" ฿ "+_vm._s(item.ProductPRU[0].exceedUc)+" ")])])])}),0)]):_vm._e(),_c('div',{staticClass:"modal fade",staticStyle:{"z-index":"9999"},attrs:{"id":"reccomendModal","data-backdrop":"false"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_vm._m(1),_c('detail',{attrs:{"cartTotal":_vm.cartTotal,"SKUObjectId":_vm.SKUObjectId},on:{"update-cartTotal":_vm.updateCartTotal}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('span',{attrs:{"hidden":""}},[_vm._v(" X ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"close",staticStyle:{"z-index":"1080"},attrs:{"data-dismiss":"modal"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('img',{attrs:{"width":"10%","src":"/img/close.png"}})])])
}]

export { render, staticRenderFns }