<template>
    <transition name="slide-fade">
        <div style="width: 95%;z-index: 9999;" v-if="visible" :class="notificationClass">
            <div class="d-flex p-1">
                <div class="pt-2 pl-2">
                    <i style="font-size: xx-large;" :class="iconClass"></i>
                </div>
                <div class="flex-grow-1 align-self-center pl-3">
                    <strong class="h5">{{ title }}</strong><br>
                    <span>{{ text }}</span>
                </div>
                <div class="ml-auto align-self-center pt-1 pr-2">
                    <i style="font-size: x-large;" @click="closeNotification()" class="fi fi-rr-cross"></i>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'info',
            required: true

        }
    },
    data() {
        return {
            visible: false
        }
    },
    computed: {
        notificationClass() {
            return `notification-${this.color}`;
        },
        iconClass() {
            switch (this.color) {
                case 'success':
                    return 'fi fi-rr-badge-check'
                case 'warning':
                    return 'fi fi-rr-triangle-warning'
                case 'danger':
                    return 'fi fi-rr-light-emergency-on'
                case 'info':
                    return 'fi fi-rr-info '
                case 'notify':
                    return 'fi fi-rr-bell'
                default:
                    return 'fi fi-rr-badge-check'
                    break;
            }


        }
    },
    methods: {
        pushNotification() {
            this.visible = true;
            setTimeout(() => {
                this.visible = false;
            }, 3000)

        },
        closeNotification() {
            this.visible = false;
        }
    }
}
</script>

<style scoped>
.notification-success,
.notification-warning,
.notification-danger,
.notification-info,
.notification-notify {
    position: fixed;
    top: 20px;
    right: 10px;
    color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(47, 47, 47, 0.2);
    z-index: 1000;
    opacity: 1;
    /* Ensures text and elements inside have full opacity */
}

.notification-notify {
    background-color: rgb(64, 64, 64);
    /* Green with 0.7 opacity */
}

.notification-success {
    background-color: rgba(92, 176, 7, 1);
    /* Green with 0.7 opacity */
}

.notification-warning {
    background-color: rgba(255, 123, 7, 1);
    /* Yellow with 0.7 opacity */
}

.notification-danger {
    background-color: rgba(220, 53, 69, 1);
    /* Red with 0.7 opacity */
}

.notification-info {
    background-color: rgb(44, 170, 216);
    /* Blue with 0.7 opacity */
}

.color-theme {
    color: var(--bg-color-theme);
}

.notification button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    opacity: 1;
    /* Ensures the button text has full opacity */
}

.slide-fade-enter-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-leave-active {
    transition: opacity 0.5s ease;
}

.slide-fade-enter {
    transform: translateX(100%);
    opacity: 0;
}

.slide-fade-leave-to {
    opacity: 0;
}
</style>
